import {
  LOADING,
  RESET_TRANSACTION,
  GET_TRANSACTIONS,
  GET_TRANSACTION_DETAIL,
  NEW_TRANSACTION,
} from '../constants';

const INIT_STATE = {
  transactions: [],
  transactionDetail: null,
  loading: false
};

const TransactionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case RESET_TRANSACTION:
      return {
        ...state,
        transactionDetail: action.transaction,
      };

    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
      };

    case GET_TRANSACTION_DETAIL:
      return {
        ...state,
        transactionDetail: action.transactionDetail,
        loading: action.loading
      };

    case NEW_TRANSACTION:
      return {
        ...state,
        transactionDetail: action.transactionDetail,
        loading: action.loading
      };

    default:
      return state;
  }
};

export default TransactionsReducer;
